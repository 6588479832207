<template>
	<!-- 项目-需求-建任务 -->
<div>
	<GModule class="container">
		<GTitle name="建任务" style="margin: 20px;"></GTitle>
		<div class="detail-all">
			<a-form
				class="need-box"
			    :model="formState"
			    name="basic"
			    :label-col="{ span: 8}"
			    :wrapper-col="{ span: 10}"
			    autocomplete="off"
				:scrollToFirstError='true'
			    @finish="onFinish">
				<a-form-item
					label="优先级" 	
					name="type" 
					:colon= "false"
					:rules="[{ required: false, message: '请选择优先级' }]">
				    <a-dropdown>
				        <template #overlay>
				            <a-menu selectable @click="handleLevelClick">
				    			<a-menu-item v-for="(itm,ind) in levelArr" :key="ind">{{itm}}</a-menu-item>
				            </a-menu>
				        </template>
				        <a-button>
				    		{{levelArr[levelIndex]}}
				            <DownOutlined />
				        </a-button>
				    </a-dropdown>
				</a-form-item>
				<a-form-item
					label="指派给" 
					name="type" 
					:colon= "false" 
					:rules="[{ required: false}]">
					<a-form-item-rest>
					<div class="check-box" >
						<a-col v-for="(mitem,mindex) in manArr" :key="mindex">
						  <a-checkbox :checked="mitem.isCheck" @click="changeClick(mitem,mindex)">{{mitem.nickname}}</a-checkbox>
						  <a-row class="child" v-if="mitem.isCheck">
							  <template v-if="selectedDate == '原始需求'">
								  <a-checkbox :checked="mitem.has_static == 'yes' ? true : false" @click="childChange(0,mitem,mindex)">静态</a-checkbox>
								  <a-checkbox :checked="mitem.has_union == 'yes' ? true : false" @click="childChange(1,mitem,mindex)">联调</a-checkbox>
							  </template>
							  <template v-else>
								  <a-checkbox :checked="false" :disabled="true">静态</a-checkbox>
								  <a-checkbox :checked="false" :disabled="true">联调</a-checkbox>
							  </template>
						  </a-row>
						</a-col>
					</div>
					</a-form-item-rest>
				</a-form-item>
				<a-form-item label="任务列表">
				    <a-table
				    	:columns="columnsHe" 
				    	:data-source="data" 
				    	:scroll="{ x: 500 }" 
				    	bordered
				    	:pagination="false">
						<template #bodyCell="{ column ,record,index}">
							<template v-if="column.dataIndex === 'index'">
								{{index}}
							</template>
						</template>	
				    </a-table>
				</a-form-item>
				<a-form-item label="需求类型" 	
					name="type" 
					:colon= "false"
					:rules="[{ required: false, message: '请选择需求类型' }]">
				    <a-dropdown>
				        <template #overlay>
				            <a-menu selectable @click="handleDateClick">
				    			<a-menu-item v-for="(itm,ind) in selectDate" :key="itm.id">{{itm.date.name}}</a-menu-item>
				            </a-menu>
				        </template>
				        <a-button>
				    		{{selectedDate}}
				            <DownOutlined />
				        </a-button>
				    </a-dropdown>
				    <!-- {{need.name}} -->
				</a-form-item>
				<a-form-item label="需求模块">
					<div v-if="modularsArr.length  == 0" style="color:#999;">请在左侧模块菜单选择需求点（第三级）</div>
				    <a-breadcrumb separator=">" style="width: 400px;margin-bottom: 14px;" v-else>
				        <a-breadcrumb-item 
				    		v-for="(item,index) in modularsArr" 
				    		:key="index">
				    		{{item}}
				    	</a-breadcrumb-item>
				    </a-breadcrumb>
				</a-form-item>
				<a-form-item label="需求描述">
				    <a-textarea style="height: 150px;" :value="info.describe" disabled/>
				</a-form-item>
				<a-form-item
					label="需求补充" 
					name="describe" 
					:colon= "false" 
					:rules="[{ required: false, message: '请输入需求补充' }]">
				    <a-textarea placeholder="需求补充" style="height: 150px;" v-model:value="formState.describe" />
				</a-form-item>
				<a-form-item label="参数/逻辑说明">
				    <a-textarea style="height: 90px;" :value="info.explain" disabled/>
				</a-form-item>
				<a-form-item label="需求图片">
				    <div class="img-scroll" @click="openSwiper(0)">
				    	<a-image :preview="false" :width="90" :src="itm" v-for="(itm,ind) in info.imgArr" :key="ind"/>
				    </div>
				</a-form-item>
				<a-form-item label="图片补充" name="imgs" 
					:colon= "false" 
					:rules="[{ required: false}]">

				    <a-upload
						name="file"
						v-model:file-list="fileList"
						:maxCount='8'
						list-type="picture-card"
						:customRequest ="customRequest"
						@remove="remove">
						<div class="addimg" v-if="fileList.length < 8">
							<plus-outlined />
							<p style="color: #999999;">点击上传凭证</p>
						</div>
				    </a-upload>
				</a-form-item>
				<a-form-item  :wrapper-col="{ offset:12}">
				  <a-button class="btn clo6" @click="cancel()">返回</a-button>

				<!-- <a-form-item  :wrapper-col="{ offset:11}"> -->
				  <a-button class="btn clo3" @click="reset()">取消</a-button>
				  <a-button class="btn clo3" html-type="submit">提交</a-button>
				<!-- </a-form-item> -->

				</a-form-item>
			</a-form>
		</div>
	</GModule>	
</div>		
</template>

<script>
	export default{
		name:'TaskProjectNeedDetail',
		data(){
			return{
				formState :{
					describe:'',
					images:[]
				},
				columnsHe: [{
				    title: '任务ID',
				    width:70,
				    dataIndex: 'index',
				    fixed: 'left'
				  }, {
				    title: '任务描述',
				    width: 120,
				    dataIndex: 'describe'
				  }, {
				    title: '指派给',
				    width: 120,
				    dataIndex: 'user_nickname',
						fixed: 'right'
				  }],
				data: [],
				id:0 ,//详情id
				info:{} ,// 需求详情
				modularsArr:[] ,// 需求模块
				need:{} ,//需求类型
				manArr:[],
				manIndex:0,
				userIds:[] ,// 指派人ids
				levelArr:{} ,// 优先级列表
				levelIndex:'commonly',
				record_id:0, // 需求记录单列id
				fileList:[],
				productArr:[],
				activeKey:0,
			}
		},
		computed:{
			proId(){
				return this.$store.state.proId  
			},
			selectedMemu(){
				return this.$store.state.selectedMemu
			},
			selectDate(){
				let arr = [],
					val = this.selectedMemu;
				if(val){
					if(val.length==3&&val[val.length - 1].demand.length){
						arr = JSON.parse(JSON.stringify(val[val.length - 1].demand))
					}
				}
				return arr;
			},
			selectedDate(){
				let val = this.selectedMemu;
				if(val&&val.length==3&&val[val.length - 1].demand.length){
					let obj = val[val.length - 1].demand.find(it=>it.id==this.id);
					if(obj){
						return obj.date.name
					}else{
						return '无'
					}
				}
			},
		},
		watch:{
			selectedMemu(val,old){
				// 选择左侧模块获取需求详情
				console.log('selectedMemu ===>',val)
				if(val){
					if(val.length==3&&val[val.length - 1].demand.length){
						// return
						this.id = val[val.length - 1].demand[0].id
						// if(this.id){
						// 	this.getDetail()
						// }
					}
				}
			},
			id(){
				if(this.id)this.getDetail();
			}
		},
		async created() {
			let isId = 0;// this.$route.query.isRecord == 1 && this.selectedMemu.length > 0 ? this.selectedMemu[this.selectedMemu.length - 1].demand_id : 
			if(this.$route.query.isRecord == 1 && this.selectedMemu.length == 3 && this.selectedMemu[this.selectedMemu.length - 1].demand.length){
				isId = this.selectedMemu[this.selectedMemu.length - 1].demand[0].id
			}

			if(isId){
				this.id = isId || 0
			}else{
				this.id = this.$route.query.demandId  || 0
			}
			this.record_id = this.$route.query.record_id || 0 // 需求记录单列id
			let isRecord = this.$route.query.isRecord || 0 // 转任务标记
			if(isRecord){
				// 填充数据
				this.formState.describe = this.$route.query.describe // 需求描述
			}
			
			// if(this.id){
			// 	this.getDetail()
			// }
			// 获取需求日期列表
			let resk = await this.$utils.api.post({
				url:"/demand_date/index",
				data:{
					sort:'id',
					order:'asc'
				},
				result:1
			})
			if(resk.code == 1){
				this.productArr = resk.data.rows
				if(this.productArr.length > 0){
					this.activeKey = this.productArr.length - 1
				}
			}
			// 获取指派人
			let rr = await this.$utils.api.post({
				url:'/team/team_selectpage',
				data:{
					project_id: this.proId,
					label:'develop'
				},
				result:1
			})
			if(rr.code == 1){
				if(rr.data.list&&rr.data.list.length > 0){
					for(let i in rr.data.list){
						rr.data.list[i].isCheck = false
						rr.data.list[i].has_static = 'no'
						rr.data.list[i].has_union = 'no'
					}
				}
				this.manArr = rr.data.list ? rr.data.list : []
			}
			// 获取优先级
			let r = await this.$utils.api.get({
				url:'/demand/setting',
				result:1
			})
			if(r.code == 1){
				this.levelArr = r.data.priorityList
				console.log("骄傲家家爱",isRecord)
				if(isRecord){
					// 填充数据
					this.levelIndex = this.$route.query.priority // 优先级
				}else{
					this.levelIndex = 'commonly' // 默认显示一般
				}
			}
		},
		mounted() {
			this.$store.commit('setImgOtherArr',this.imgArr)
		},
		methods:{
			async getDetail(){
				// 获取需求详情
				let res = await this.$utils.api.post({
					url:'/demand/getRow',
					data:{
						ids:this.id
					},
					result:1
				})
				if(res.code == 1){
					this.modularsArr = res.data.modulars
					res.data.imgArr = res.data.images.length > 0 ? res.data.images.split(',') : []
					this.info = res.data
					this.need = res.data.date;
					console.log('this.need ===>', this.need, res.data.date)
					this.data = res.data.tasks
				}
			},
			async customRequest (e) {
				// 执行图片上传
				let that = this
				let res = await this.$utils.api.upLoad(e)
				if(res){
					setTimeout(()=>{
						that.$utils.msgSuc('上传成功')
						// 上传成功修改文件状态
						that.fileList[that.fileList.length-1].status = 'done' 
						// 图片封面预览
						that.fileList[that.fileList.length-1].thumbUrl = res.data.fullurl 
						// 图片集合
						that.formState.images.push(res.data.fullurl)
					},500)
				}
			},
			remove(e){
				// 删除图片
				this.formState.images = this.formState.images.filter((item) => {
				    return item != e.thumbUrl
				})
				this.fileList = this.fileList.filter((item) => {
				    return item.thumbUrl != e.thumbUrl
				})
			},
			changeClick(item,index){
				this.manArr[index].isCheck = !this.manArr[index].isCheck
				if(this.manArr[index].isCheck){
					this.manArr[index].has_static = 'yes'
					this.manArr[index].has_union = 'yes'
					let info = {
						user_id:item.id,
						has_static:'yes',
						has_union:'yes'
					}
					this.userIds.push(info) // 添加
				}else{
					this.manArr[index].has_static = 'no'
					this.manArr[index].has_union = 'no'
					this.userIds.splice(index,1) // 移除
				}
			},
			childChange(type,item,index){
				if(type == 1){
					this.manArr[index].has_union = item.has_union == 'yes' ? 'no' : 'yes'
				}else{
					this.manArr[index].has_static = item.has_static == 'yes' ? 'no' : 'yes'
				}
				for(let i in this.userIds){
					if(this.userIds[i].user_id == item.id){
						this.userIds[i].has_static = this.manArr[index].has_static
						this.userIds[i].has_union = this.manArr[index].has_union
					}
				}
			},
			handleLevelClick(e){
				this.levelIndex = e.key
			},
			handleDateClick(e){
				console.log()
				this.id = e.key
			},
			openSwiper(){
			  // 弹出图片列表
			  this.$store.commit('setSwiper',1)
			},
			openDownloadImgs(){
				// 弹出图片下载
				this.$store.commit('setImgOther',1)
			},
			async onFinish(){
				// if(!this.id){
				// 	this.$utils.msgWar('请在左侧模块菜单选择需求点（第三级）')
				// 	return
				// }
				var userIds = JSON.parse(JSON.stringify(this.userIds))
				if(userIds.length == 0){
					this.$utils.msgWar('请选择指派人')
					return
				}
				if(this.formState.describe == ""){
					this.formState.describe = '以需求为准'
				}
				console.log('selectedDate===>',this.selectedDate)
				if(this.selectedDate != '原始需求'){
					// 新增变更需求的任务不需要静态联调
					for(let i in userIds){
						userIds[i] = {
							user_id:userIds[i].user_id,
							date_type: 'all'
						};
						// this.userIds[i].has_static =  !0;
						// this.userIds[i].has_union =  !0; 
					}
				}else{
					let newl = [];
					for(let i in userIds){
						// this.userIds[i].has_static = 'all';
						if(userIds[i].has_static=='yes'){
							newl.push({
								user_id:this.userIds[i].user_id,
								date_type:'static'
							})
						}
						if(userIds[i].has_union=='yes'){
							newl.push({
								user_id:this.userIds[i].user_id,
								date_type:'union'
							})
						}
						// this.userIds[i].has_static =  !0;
						// this.userIds[i].has_union =  !0; 
					}
					userIds = newl;
				}
				// 提交
				let res = await this.$utils.api.post({
					url:'/task/create_task',
					data:{
						describe:this.formState.describe,
						demand_id:this.id,
						data:userIds,
						priority:this.levelIndex,
						record_id:this.record_id,
						images:this.formState.images,// .length > 0 ? this.formState.images.join(',') : ''
					},
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('提交成功')
					this.cancel()
				}
			},
			cancel(){
				// 取消
				this.$router.back()
			},
			reset(){
				// 取消 、 重置
				this.levelIndex = 'commonly' // 默认显示一般
				this.formState.describe = ''
				for(let i in this.manArr){
					this.manArr[i].isCheck = false
					this.manArr[i].has_static = 'no'
					this.manArr[i].has_union = 'no'
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
.container{
	margin: 20px;
	padding: 0 0 50px 0;
	.detail-all{
		padding: 30px 0;
		max-height: 92%;
		.need-box{
			.btn{
				margin-right: 10px;
			}
			.img-scroll{
				height: 90px;
				overflow-x: auto;
				overflow-y: hidden;
				display: flex;
				align-items: center;
				&::-webkit-scrollbar {
					height: 0px; // 控制滚动条
				}
				&::-webkit-scrollbar-thumb{
					background-color: #a5ddff; // 滚动条颜色
				}
				&:hover{
					&::-webkit-scrollbar {
						height: 8px;

					}
				}
			}
			.overimg-box2{
				.overimg{
					display: flex;
					height: 90px;
					overflow: hidden;
				}
			}
			.check-box{
				width: 100%;
				padding: 10px 20px;
				border: 1px solid #ddd;
				max-height: 200px;
				overflow-y: auto;
				&::-webkit-scrollbar {
					width: 0px; // 控制滚动条
				}
				&::-webkit-scrollbar-thumb{
					background-color: #bfbfbf; // 滚动条颜色
				}
				&:hover{
					&::-webkit-scrollbar {
						width: 1px;
					}
				}
				.child{
					margin-left: 20px;
					p{
						font-size: 12px;
						color: #999999;
					}
				}
			}
		}
	}
}
.caozuo{
	display: flex;
	justify-content: space-around;
	align-items: center;
	p{
		margin: 0;
		font-size: 12px;
		color: #407cff;
	}
}
</style>